import { getPulseTracker } from "..";

const trackSubscriptionClick = (url: string) => {
  const pulseTracker = getPulseTracker();

  pulseTracker?.executePulseCommand("track", "engagementEvent", {
    action: "Click",
    object: {
      id: "button-premiumInformationBoxSubscribe",
      type: "UIElement",
      elementType: "Button",
      name: "PremiumInformationAction",
    },
    target: {
      id: "checkout",
      type: "ExternalContent",
      name: "Web Salesposter",
      url,
    },
  });
};

export { trackSubscriptionClick };
