import { getNewsMediaPulseTracker } from "..";
import { capitalizeFirstLetter } from "../helper";

const trackViewFrontpage = (id: string) => {
  const newsMediaPulseTracker = getNewsMediaPulseTracker();

  newsMediaPulseTracker?.executeNewsMediaPulseCommand("trackViewFrontpage", {
    object: {
      id: id,
      name: capitalizeFirstLetter(id),
    },
  });
};

export { trackViewFrontpage };
