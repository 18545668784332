import type { Article, Author, Resource } from "../types/content";
import type { ArticleTeaserTargetSubset } from "../types/pulse";

const isArticlePathname = /^\/[^/]*\/a\/[^/]*$/;

const getActorPromise = async (userId?: string, isPremium?: boolean) => {
  if (!userId) {
    return {
      id: undefined,
    };
  }

  return {
    id: parseInt(userId),
    subscriptionName: isPremium ? "Premium" : "No SubscribedTopic",
    realm: "schibsted.com",
  };
};

const getAuthorsEmails = (authors: Author[]) =>
  authors.reduce((emails: string[], author) => {
    const emailContact = author.contacts?.find((contact) => {
      return contact.type === "email";
    });

    if (emailContact?.value) {
      emails.push(emailContact.value);
    }

    return emails;
  }, []);

const getWordCount = (resources: Resource[]) => {
  const text = resources.reduce((text, resource) => {
    if (resource.type === "Title" || resource.type === "Subheading") {
      return text.concat(" ", resource.text.value);
    }

    if (resource.type === "Text" || resource.type === "OriginByline") {
      return text.concat(
        " ",
        resource.paragraphs.reduce((pText, paragraph) => {
          if ("items" in paragraph) {
            return pText.concat(
              " ",
              paragraph.items.map((item) => item.value).join(" "),
            );
          } else if (paragraph.text) {
            return pText.concat(" ", paragraph.text.value);
          }

          return pText;
        }, ""),
      );
    }

    return text;
  }, "");

  return text.match(/([\wåäö]+)/gim)?.length || 0;
};

const getSourceName = (referer: string) => {
  const refererURL = new URL(referer);

  if (isArticlePathname.test(refererURL.pathname)) {
    return "Article";
  }

  const route = refererURL.pathname.match(/^\/[^/]*/);

  if (!route) {
    return undefined;
  }

  switch (route[0]) {
    case "/":
      return "Top";
    case "/senaste":
      return "Latest";
    default:
      return undefined;
  }
};

const capitalizeFirstLetter = (string: string) => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
};

interface GetTeaserObjectArgs {
  article: Article;
  imageUrl?: string;
  context?: string;
  curateContext?: string;
  positionInBundle?: number;
  index: number;
}

const getTeaserObject = ({
  article,
  imageUrl,
  index,
  context,
  curateContext,
  positionInBundle,
}: GetTeaserObjectArgs) => {
  return {
    id: `teaser-${article.article_id}`,
    type: "UIElement",
    elementType: "Teaser",
    name: article.teaser.title.value,
    layout: {
      elementSize: article.teaser.layout === "large" ? "large" : "small",
      imageUrl,
      name: article.teaser.layout === "large" ? "Large" : "Small",
    },
    placement: {
      curateContext,
      primaryPosition: index + 1,
      positionInBundle,
    },
    "spt:custom": {
      context,
      is_sponsored: article.meta?.is_sponsored,
      sponsor: article.sponsor,
      vignette: article.story_vignette?.title,
      story: article.story?.title,
      category: article.category.title,
    },
  };
};

const getTeaserTarget = (
  article: ArticleTeaserTargetSubset,
  baseUrl: string,
) => {
  const trailingSlash = baseUrl.slice(-1) === "/";

  if (trailingSlash) {
    baseUrl = baseUrl.slice(0, -1);
  }

  return {
    id: article.article_id,
    type: "Article",
    name: article.title.value,
    url: `${baseUrl}/a/${article.article_id}`,
    accessLevel: article.is_premium ? "Paid" : "Free",
    accessLevelName: article.is_premium ? "premium" : "free",
    newsroom: process.env.NEXT_PUBLIC_UI_THEME === "core" ? "omni" : "omninext",
  };
};

export {
  getTeaserTarget,
  getTeaserObject,
  capitalizeFirstLetter,
  getSourceName,
  getWordCount,
  getAuthorsEmails,
  getActorPromise,
};
export type { GetTeaserObjectArgs };
